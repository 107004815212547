import type { ConfirmationOptions } from 'primevue/confirmationoptions';

type Severity = 'success' | 'danger';

export type Confirm = (
  confirmationOptions: ConfirmationOptions,
  severity?: Severity
) => Promise<boolean>;

export const confirmKey = Symbol('confirm') as InjectionKey<Confirm>;
