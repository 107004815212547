import type { MenuItem } from 'primevue/menuitem';
import { useRouter } from 'vue-router';

const homeBreadcrumb: MenuItem = { homeIcon: fasHome, to: 'Dashboard' };

const activeBreadcrumb = ref<MenuItem>();
const routeBreadcrumbs = ref<MenuItem[]>([]);

const breadcrumbs = computed<MenuItem[]>(() => {
  const items: MenuItem[] = [
    homeBreadcrumb,
    ...routeBreadcrumbs.value,
  ];

  if (activeBreadcrumb.value) {
    items.push(activeBreadcrumb.value);
  }

  return items;
});

export function setActiveBreadcrumb(label: string) {
  activeBreadcrumb.value = { label };
}

export function useBreadcrumbs() {
  const router = useRouter();
  router.afterEach(to => {
    routeBreadcrumbs.value = to.meta.breadcrumbs || [];
    activeBreadcrumb.value = undefined;
  });

  return breadcrumbs;
}
