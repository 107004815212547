<template>
  <div class="layout-content-header flex justify-between items-center">
    <i-breadcrumbs />
  </div>
</template>

<style>
.layout-content-header {
  height: var(--p-layout-header-height);
  padding: 0.5rem 2rem;
  background: var(--p-surface-0);
  border-bottom: 0.07rem solid var(--p-content-border-color);
}
</style>
