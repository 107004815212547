<script setup lang="ts">
import { useBreadcrumbs } from '@/composables/breadcrumbs';

const breadcrumbs = useBreadcrumbs();
</script>

<template>
  <p-breadcrumb :model="breadcrumbs">
    <template #item="{ item, props }">
      <router-link v-if="item.to" v-bind="props.action" :to="{ name: item.to }">
        <fa-icon v-if="item.homeIcon" class="text-primary-800" :icon="item.homeIcon" />
        <span v-else class="text-primary-500">{{ item.label }}</span>
      </router-link>
      <span v-else>{{ item.label }}</span>
    </template>
  </p-breadcrumb>
</template>
