<script setup lang="ts">
import { customerService } from '@/services/customer';

const customers = ref<unknown[]>([]);
const loading = ref<boolean>(false);
const expandedRows = ref([]);

const loadCustomerTableData = async () => {
  loading.value = true;

  try {
    customers.value = await customerService.getCustomers();
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};

const formatDate = (date: string) => new Date(date).toLocaleDateString('de-DE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

loadCustomerTableData();
</script>

<template>
  <p-message
    severity="info"
  >
    Diese Tabelle zeigt alle Kunden an.
    Sie steht nur im Entwicklungsmodus zur Verfügung,
    kann bei Datenbankänderungen nicht funktionieren
    und die Daten können sich ändern.
  </p-message>
  <p-data-table
    v-model:expandedRows="expandedRows"
    scrollable
    scroll-height="70vh"
    :value="customers"
    :loading="loading"
    paginator
    striped-rows
    :rows="10"
    size="small"
  >
    <p-column field="customer_id" header="ID" />
    <p-column field="first_name" header="Vorname" />
    <p-column field="last_name" header="Nachname" />
    <p-column field="phone_number" header="Phone" />
    <p-column field="email_address" header="Email" />
    <p-column field="date_of_birth" header="Geburtstag">
      <template #body="{ data }">
        {{ formatDate(data.date_of_birth) }}
      </template>
    </p-column>
    <p-column field="address" header="Anschrift">
      <template #body="{ data }">
        {{ data.address.street_name ?? '' }} {{ data.address.house_number }} <br>
        {{ data.address.zip_code }} {{ data.address.city }}
      </template>
    </p-column>
    <p-column field="contracts" header="Verträge">
      <template #body="{ data }">
        {{ data.contracts.length }}
      </template>
    </p-column>
    <p-column expander style="width: 3em" />

    <template #expansion="{ data }">
      <p-data-table
        :value="data.contracts"
      >
        <p-column field="contract_id" header="ID" />
        <p-column field="start_date" header="Start">
          <template #body="{ data: contract }">
            {{ formatDate(contract.start_date) }}
          </template>
        </p-column>
        <p-column field="end_date" header="Ende">
          <template #body="{ data: contract }">
            {{ formatDate(contract.end_date) }}
          </template>
        </p-column>
        <p-column field="status" header="Status" />
        <p-column field="product_type" header="Typ" />
        <p-column field="contract_rates" header="Rates" />
      </p-data-table>
    </template>
  </p-data-table>
</template>
