import type { User } from '@/models/user';
import type { Permission } from '@/models/user-role';

interface LoggedInState {
  loggedIn: true;
  user: User;
}

interface LoggedOutState {
  loggedIn: false;
  user?: null;
}

type UserStoreState = LoggedInState | LoggedOutState;

/**
 * Simple user store implemented via reactivity api.
 * Might switch to Pinia in the future if more store features are needed.
 * @see https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api
 */
export const userStore = reactive<UserStoreState>({
  loggedIn: false,
});

/**
 * Returns a computed bool ref that resolves true if the user has the given permission.
 * @param {boolean} invert - If enabled, the ref value will be inverted, meaning it will resolve true if the user lacks the given permission.
 */
export function usePermission(permission: Permission, { invert } = { invert: false }) {
  return computed(() => (userStore.loggedIn && userStore.user.role[permission]) === !invert);
}
