import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USERPOOL_ID,
      userPoolClientId: import.meta.env.VITE_USERPOOL_CLIENT_ID,
    },
  },
});
