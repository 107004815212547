<script setup lang="ts">
import type Popover from 'primevue/popover';

const panel = ref<typeof Popover>();

function close() {
  panel.value?.hide();
}
</script>

<template>
  <div>
    <i-button v-bind="$attrs" @click="panel?.toggle" />
    <p-popover ref="panel">
      <slot :close />
    </p-popover>
  </div>
</template>
